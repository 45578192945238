<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="blockCustomerModal"
      :title="$t('Block Customer')"
      :buttons-hidden="true">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-textarea  counter="100" v-validate="'required'" name="reason" :placeholder="$t('Reason')" rows="3" v-model="data.reason" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="blockCustomer" class="active-btn btn">{{ $t('Block') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['blockCustomerModal', 'data'],
  data () {
    return {
    }
  },
  methods: {
    blockCustomer () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const customer = {
            is_blocked: true,
            blocked_reason: this.data.reason
          }
          if (!this.isOnline) this.$emit('blockCustomerModal', false)
          sendRequest(false, false, this, `api/v1/customers/${this.data.customer.id}/`, 'patch', customer, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['User'] + i18nData[this.$i18n.locale][' has been blocked successfully!'],
                position: 'top-center'
              })
              this.$emit('blockCustomerModal', false)
              this.$emit('loadShipments')
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('blockCustomerModal', false)
      this.$emit('loadShipments')
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>