<template>
  <div>
    <div @click="filterDropdownFlag = false" v-if="filterDropdownFlag === true" class="fixed inset-0 bg-transparent filter-z-index"></div>
    <div class="relative w-76" :class="filterDropdownFlag ? 'filter-z-index' : ''">
      <div @click="filterDropdownFlag = !filterDropdownFlag" class="flex gap-4 cursor-pointer bg-white shadow p-2 rounded-lg whitespace-no-wrap overflow-hidden">
        <p class="truncate w-full capitalize font-medium text-sm">{{filters.length === 0 ? $t('All Selected') : filters.map(item => $t(item.replaceAll('_', ' '))).join(', ')}}</p>
        <div class="text-right flex items-center">
          <feather-icon class="w-4 h-4" :icon="filterDropdownFlag ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
        </div>
      </div>
      <div v-if="filterDropdownFlag" class="absolute w-full left-0 bg-white shadow-md p-2 rounded-lg " style="top: 40px;">
        <div @click="changeFilter(item.value)" v-for="item in statusFilters" :key="item.index">
          <vs-checkbox class="mb-2 " :checked="filters.length === 0 && item.value === 'all' ? true : filters.includes(item.value)" :vs-value="item.value">
            <span class="capitalize">{{ $t(item.name) }}</span>
          </vs-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['statusFilters', 'filters'],
  data () {
    return {
      filterDropdownFlag: false
    }
  },
  methods: {
    changeFilter (type) {
      this.$emit('filterValue', type)
      this.$emit('changeFilter')
    }
  }
}
</script>

<style>
.filter-z-index {
  z-index: 9999;
  user-select: none;
}
</style>